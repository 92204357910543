import React from 'react'
import Page from '../Page'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  children,
  meta_title,
  meta_desc,
  tags,
  book,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <Page>
      <div className='font-sans container m-auto flex flex-col py-8 text-center px-6'>
        <div className='hero-article'>
          <h1 className='my-8 max-w-full m-auto text-3xl md:text-4xl lg:text-5xl font-medium font-primary'>
            {title}
          </h1>
          <h1 className='max-w-full m-auto text-2xl md:text-3xl lg:text-4xl font-medium font-primary'>
            {book}
          </h1>
          <img className='m-auto' src={cover} alt={title} />
          <PostContent
            className='mt-1 lg:mt-4 max-w-4xl m-auto leading-loose mb-6 text-left'
            content={content}
          />
          {children}
          <hr />
        </div>
      </div>
    </Page>
  )
}

export default ArticleTemplate
